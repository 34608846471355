* {
    box-sizing: border-box;
}

html {
    margin: 0;
    height: 100%;
    overflow: hidden
}

body {
    margin: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    overflow-x: hidden;
}

.v-header {
    height: 100vh;
    display: flex;
    align-items: center;
    color: #fff;
}

.container {
    max-width: 960px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
    text-align: center;
}

.fullscreen-video-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.fullscreen-video-wrap video {
    min-width: 100%;
    min-height: 100%;
}

.header-overlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
    z-index: 1;
    opacity: 0.75;
}

.header-content {
    z-index: 2;
}

.header-content h1 {
    font-size: 50px;
}

.header-content p {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 2rem;
}

.btn {
    background: aquamarine;
    color: #fff;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    text-decoration: none;
}

@media (max-width: 960px) {
    .container {
        padding-right: 3rem;
        padding-left: 3rem;
    }
}

.cards-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    z-index: 2;
    margin: 30px auto;
    width: 300px;
    height: 300px;
    border-radius: 40px;
    box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: 0.4s;
}

.card .card_image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
}

.card .card_image img {
    width: inherit;
    height: inherit;
    border-radius: 40px;
    object-fit: cover;
}

.card .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    margin-top: -80px;
    height: 40px;
}

.card:hover {
    transform: scale(0.9, 0.9);
    box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.title-white {
    color: white;
}

.title-black {
    color: black;
}

@media all and (max-width: 960px) {
    .card-list {
        /* On small screens, we are no longer using row direction but column */
        flex-direction: column;
    }
}

.grid-container {
    z-index: 2;
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}

.grid-item {
    padding: 50px;
    text-align: center;
}

.custom-vote-btn {
    background-color: #ffffff;
    border-radius: 10px;
    height: 35px;
    width: 70px;
}

.custom-vote-btn:hover {
    background-color: black;
    color: white;
}

.kk-image {
    height: 20%;
    z-index: 3;
    margin-left: 5px;
    margin-right: 5px;
}

@import url(//use.fontawesome.com/releases/v5.13.0/css/all.css);
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

button {
    font-size: 1.5rem;
    font-weight: 400;
    padding: .5rem;
    border-radius: .75rem;
    background: #000;
    color: #fff;
    border-width: .1rem;
    border-style: solid;
    border-color: #000;
    cursor: pointer;
}

button.a3 {
    font-family: 'Permanent Marker', cursive;
    background-color: white;
    border-color: transparent;
    color: #000;
    outline: none;
}

button.a3:hover {
    background-color: #000;
    border-color: #000;
    color: #fafaff;
}